export const ActionTypes = {
  //products
  SET_PRODUCTS: "SET_PRODUCTS",
  CREATE_PRODUCTS: "CREATE_PRODUCTS",
  UPDATE_PRODUCTS: "UPDATE_PRODUCTS",
  DELETE_PRODUCTS: "DELETE_PRODUCTS",
  SELECTED_PRODUCT: "SELECTED_PRODUCT",
  GET_PRODUCT_SUCCESS: "GET_PRODUCT_SUCCESS",
  GET_PRODUCT_PENDING: "GET_PRODUCT_PENDING",
  GET_PRODUCT_ERROR: "GET_PRODUCT_ERROR",
  ADD_PRODUCTS_PENDING: "ADD_PRODUCTS_PENDING",
  REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
  CLEAR_PRODUCT: "CLEAR_PRODUCT",
  
  // bag
  UPDATE_PAGE: "UPDATE_PAGE",
  ADD_BAG: "ADD_BAG",
  ADD_CHECKOUT: "ADD_CHECKOUT",
  BAG: "BAG",
  
  // user
  USER_REGISTER_PENDING: "USER_REGISTER_PENDING",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_PENDING: "USER_LOGIN_PENDING",
  USER_LOADED: "USER_LOADED",
  UPDATE_USER: "UPDATE_USER",
  SIGN_OUT: "SIGN_OUT",

  // categories
  GET_CATEGORY_PENDING: "GET_CATEGORY_PENDING",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_ERROR: "GET_CATEGORY_ERROR",
};
