// import logo from './logo.svg';
import './App.css';

import Router from "./configs/router";

function App() {
  return <Router />;
}


export default App;
